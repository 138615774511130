import React from 'react'
import '../Css/Main.css'
import whitelogo from './Images/logo-white.png'
import dental from './Images/dental.jpg'
import { Link } from 'react-router-dom'
import di from './Images/Procedures/DentalImplants.png'
import cd from './Images/Procedures/CosmeticDentistry.png'
import rc from './Images/Procedures/RootCanals.png'
import tw from './Images/Procedures/TeethWhitening.png'
import dx from './Images/Procedures/DentalXrays.png'
import bi from './Images/Procedures/Bracesimplants.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import aa from './Images/Happy Stories/Ammara-ali.png'
import sa from './Images/Happy Stories/Shahrukh-Akram.png'
import hbh from './Images/Happy Stories/Hamza.png'
import { faGoogle, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import location from './Images/Location.png'

const Main = () => {
  return (
    <div>
      <div className='bluecover'>
        <div className='bluecoverparent1'>
        <div className='bluecovertext'> 
          <h2 style={{color: 'white'}}>Your Oral Health Matters To Us</h2>
          <hr style={{width: '120px', marginLeft: '5px', color: 'white'}} />
          <h1 style={{color: 'white', fontSize: '350%', className: 'bluecovertexth1'}}>Get Brighter Smile From Lahore's Best Dentist</h1>
        </div>
        <div className='whitelogodiv'>
          <img className='whitelogo' src={whitelogo} alt="whitelogo" />
        </div>
        </div>
        </div>

    <div className='dentalexcellence'>
      <div className='excellencephoto'>
        <img className='dentalimg' src={dental} alt="dental" />
      </div>
      <div className='excellencetext'>
        <h1 style={{fontFamily: 'sans-serif', marginTop: '20%'}}><b>Commited to Dental Excellence</b></h1>
        <hr style={{color: 'lightcoral'}} />
        <h5 style={{fontFamily: 'sans-serif'}}>Al Mairaj Dental & Cosmetics Clinic offers comprehensive and personalized</h5>
        <h5 style={{fontFamily: 'sans-serif'}}>dental & Cosmetics services in a warm and welcoming environment. With a</h5>
        <h5 style={{fontFamily: 'sans-serif'}}>team of experienced and skilled dentists, the clinic provides a range of</h5>
        <h5 style={{fontFamily: 'sans-serif'}}>treatments, from routine check-ups to advanced procedures, all at affordable</h5>
        <h5 style={{fontFamily: 'sans-serif'}}>prices. Our goal is to ensure that patients recieve the highest quality dental</h5>
        <h5 style={{fontFamily: 'sans-serif'}}>care and leave with a healthy, beautiful smile.</h5>
      </div>
    </div>

    <div className='cutc'>
      <div className='cutctext'>
        <h1 style={{fontFamily: 'sans-serif', color: 'white', marginLeft: '2%'}}><b>Call Us Today for Consultations</b></h1>
        <h5 style={{color: 'white', fontFamily: 'sans-serif', marginLeft: '2%'}}>Contact our clinic for personalized and professional healthcare services</h5>
        <h5 style={{color: 'white', fontFamily: 'sans-serif', marginLeft: '2%'}}>by calling or visiting us during our working hours.</h5>
      </div>
      <div className='cutcbutton'>
      <Link to="tel: +923218403832">
      <button style={{backgroundColor: 'white', borderRadius: '5%', padding: '2% 3% 2% 3%', border: 'none', marginTop: '5%', marginLeft: '10%'}}>Call +923218403832
      </button>
      </Link>
      </div>
    </div>

    <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', marginTop: '10%'}}><b>Our Procedures</b></h1>
    <hr style={{color: 'lightcoral'}}/>
    <h5 style={{fontFamily: 'sans-serif', textAlign: 'center'}}>At AMDC we aim to provide our patients with best treatment & effective solution, all the</h5>
    <h5 style={{fontFamily: 'sans-serif', textAlign: 'center'}}>facility one state of the art the patient treatment includes best consultation & customise</h5>
    <h5 style={{fontFamily: 'sans-serif', textAlign: 'center'}}>treatment plan to suit patient needs. We look forward to introducing you to different kind</h5>
    <h5 style={{fontFamily: 'sans-serif', textAlign: 'center'}}>of dental and skin perfect experience</h5>
    <hr style={{color: 'lightcoral'}}/>

    <div className='proceduresdiv'>
      <div className='procedures'>
        <div className='di'>
          <img className='diimg' src={di} alt="di" />
          <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Dental Implants</b></h3>
          <hr style={{color: 'white'}}/>
          <p style={{color: 'white', marginLeft: '3%', marginRight: '3%'}}>The dental implant procedure starts with an initial consultation to assess the patient's oral health and plan the treatment. During the implant placement, the dentist surgically inserts the implant into the jawbone and stiches the gum. Over several months, the implant integrates with the bone. Once healed, an abutment is placed, and a custom made crown is attached to restore the missing tooth. Regular follow up visits and proper oral hygeine are crucial for the long term success of the implant.</p>
        </div>
        <div className='cd'>
          <img className='cdimg' src={cd} alt="cd" />
          <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Cosmetic Dentistry</b></h3>
          <hr style={{color: 'white'}}/>
          <p style={{color: 'white', marginLeft: '3%', marginRight: '3%'}}>Cosmetic dentistry procedures focus on improving the appearance of person's smile. Common treatments include teeth whitening, dental veneers, are thin shells placed over the front surface of teeth to improve their colors or shape. Bonding uses tooth colored resin to fix chips or gaps, and tooth reshaping involves removing small amounts of enamel to change a tooth's length or shape. These procedures can enhance a person's smile and confidence.</p>
        </div>
        <div className='rc'>
          <img className='rcimg' src={rc} alt="rc" />
          <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Root Canal</b></h3>
          <hr style={{color: 'white'}}/>
          <p style={{color: 'white', marginLeft: '3%', marginRight: '3%'}}>Root Canal treatment is a procedure to repair and save a badly damaged or infected tooth. The dentist removes the damaged area of the tooth (the pulp), cleans and disinfects it, and then fills and seals it. Common causes for root canal treatment include deep decay, repeated dental procedures on the tooth, or a crack or chip in the tooth. Root canals can help alleviate pain and save the natural tooth, preventing the need for extraction and restoring the tooth's function.</p>
        </div>
      </div>
      <div className='proceduresdiv2'>
        <div className='procedures2'>
          <div className='tw'>
            <img className='twimg' src={tw} alt="tw" />
            <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Teeth Whitening</b></h3>
            <hr style={{color: 'white'}}/>
            <p style={{color: 'white', marginLeft: '3%', marginRight: '3%'}}>Teeth whitening is a cosmetic dental procedure that brightens and lightens the color of teeth. It involves applying a bleaching agent to the teeth, which breaks down stains and discoloration. The procedure can be done in-office by a dentist or at home using custom-made trays and a bleaching gel provided by the dentist. Teeth whitening is a safe and effective way to enhance the appearance of your smile.</p>
          </div>
          <div className='dx'>
            <img className='dximg' src={dx} alt="dx" />
            <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Dental X-Rays</b></h3>
            <hr style={{color: 'white'}}/>
            <p style={{color: 'white', marginLeft: '3%', marginRight: '3%'}}>Dental X-rays, also known as radiographs, are images of your teeth that dentists use to evaluate your oral health. During the procedure, you will be asked to bite down on a small piece of plastic while the X-ray machine is positioned next to your cheek. The X-ray image helps the dentist identify issues such as cavities, tooth decay, and problems with the bones that support your teeth. Dental X-rays are a valuble tool in preventive dentistry, allowing dentists to catch and treat dental issues early.</p>
          </div>
          <div className='bi'>
            <img className='biimg' src={bi} alt="bi" />
            <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Braces & Implants</b></h3>
            <hr style={{color: 'white'}}/>
            <p style={{color: 'white', marginLeft: '3%', marginRight: '3%'}}>Braces are orthodontic devices used to straighten and align teeth. The procedure involves bonding brackets to the teeth and connecting them with wires, which are gradually thightened to shift the teeth into the desired position. Braces are commonly used in adolescents, but adults can also benefit from them. Implants, on the other hand, are used to replace missing teeth. The procedure involves surgically placing a titanum implant into the jawbone, which serves as a sturdy anchor for a crown or bridge. Implants are a long-lasting and natural looking solution for missing teeth, providing both cosmetic and functional benefits</p>
          </div>
        </div>
      </div>
    </div>
    
    <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', marginTop: '10%'}}><b>Happy Stories</b></h1>
    <hr style={{color: 'lightcoral'}}/>
    <div className='happystoriesdiv'>
      <div className='aa'>
        <img className='aaimg' src={aa} alt="aa" />
        <hr style={{color: 'white'}}/>
        <p style={{color: 'white', marginLeft: '3%', marginRight: '3%', textAlign: 'center'}}>My experience at Al Mairaj dental and cosmetics clinic was awesome. I used to fear going to dental clinics due to the panademic and also experiencing pain during and afterwards of treatment. But when i came to Al Mairaj after lots of research i flet i was in safe hands. When i arrived at the clinic Dr Shadab and her clinic atmosphere is very friendly, safe and well-kept. Although i was with my kids. Overall mairaj is highly recommended by me</p>
        <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Ammara Ali</b></h3>
        <FontAwesomeIcon icon={faStar} style={{marginLeft: '38%', color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color:'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
      </div>
      <div className='sa'>
        <img className='saimg' src={sa} alt="sa" />
        <hr style={{color: 'white'}}/>
        <p style={{color: 'white', marginLeft: '3%', marginRight: '3%', textAlign: 'center'}}>Professional and highly qualified doctor and staff.. gone through my scaling and hydra facial. Very much satisfied with the results. Highly recommended</p>
        <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Shahrukh Alam</b></h3>
        <FontAwesomeIcon icon={faStar} style={{marginLeft: '38%', color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color:'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
      </div>
      <div className='hbh'>
        <img className='hbhimg' src={hbh} alt="hbh" />
        <hr style={{color: 'white'}}/>
        <p style={{color: 'white', marginLeft: '3%', marginRight: '3%', textAlign: 'center'}}>Here at AMDC, I got the most fabulous experience of my dental treatment history. The staff is well behaved and the doctors are very helpful throughout the treatment. I'll highly recommend AMDC. Everyone with dental or skin problems should visit once.</p>
        <h3 style={{color: 'white', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}}><b>Hamza Bin Hassan</b></h3>
        <FontAwesomeIcon icon={faStar} style={{marginLeft: '38%', color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color:'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
        <FontAwesomeIcon icon={faStar} style={{color: 'yellow'}}/>
      </div>
    </div> 

    <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', marginTop: '10%'}}><b>Review Us On</b></h1>
    <hr style={{color: 'lightcoral'}}/>
    <Link to='https://www.google.com/maps/place/Al+Mairaj+Dental+%26+Cosmetics+(AMDC)/@31.4737723,74.3788668,17z/data=!3m1!4b1!4m6!3m5!1s0x3919059f1ab46d0f:0x13aae198cdde2f2f!8m2!3d31.4737723!4d74.3788668!16s%2Fg%2F11l3p2kgrr?entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D'><FontAwesomeIcon className='google' icon={faGoogle}/></Link>
    <Link to='https://www.facebook.com/amdcclinics/'><FontAwesomeIcon icon={faFacebook} style={{marginLeft: '10%', fontSize: '200%', color: '#4267B2'}}/></Link>
    <Link to='https://www.instagram.com/amdcclinics/'><FontAwesomeIcon icon={faInstagram} style={{marginLeft: '10%', fontSize: '200%', color: '#833AB4'}}/></Link>

    <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', marginTop: '10%'}}><b>Get A Dazzling Smile On Lowest Price</b></h1>
    <hr style={{color: 'lightcoral'}}/>
    <p style={{ marginLeft: '3%', marginRight: '3%', textAlign: 'center'}}>Experience high quality dental care at affordable prices at AMDC Dental Clinic.</p>

    <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', marginTop: '10%'}}><b>Our Location</b></h1>
    <hr style={{color: 'lightcoral'}}/>
    <div className='locationdiv'>
      <Link to='https://www.google.com/maps/place/Al+Mairaj+Dental+%26+Cosmetics+(AMDC)/@31.4737768,74.3762919,17z/data=!3m2!4b1!5s0x391906746491cb51:0x466cba931bb001c2!4m6!3m5!1s0x3919059f1ab46d0f:0x13aae198cdde2f2f!8m2!3d31.4737723!4d74.3788668!16s%2Fg%2F11l3p2kgrr?entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D'>
        <img className='locimg' src={location} alt="location" />
      </Link>
    </div>
    </div>
  )
}

export default Main