import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './Images/AMDC Light Corral Logo .png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/Navbar.css'; // CSS file import kiya gaya hai

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid d-flex align-items-center">
        <NavLink className="navbar-brand d-flex align-items-center" to="/">
          <img src={logo} alt="logo" style={{ height: '60px' }} />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarNav"
          aria-expanded={isActive}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isActive ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav mx-auto d-flex justify-content-center align-items-center">
            <li className="nav-item">
              <NavLink className="nav-link custom-link" to="/" onClick={toggleMenu}>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link custom-link" to="/About" onClick={toggleMenu}>
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link custom-link" to="/Procedures" onClick={toggleMenu}>
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link custom-link" to="/" onClick={toggleMenu}>
                Reviews
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link custom-link" to="/Contact" onClick={toggleMenu}>
                Contact
              </NavLink>
            </li>
          </ul>
          <div className="ms-lg-3">
            <NavLink className="nav-link" to="tel: +923218403832" onClick={toggleMenu}>
              <button
                className="btn"
                style={{
                  backgroundColor: 'lightcoral',
                  borderColor: 'lightcoral',
                  color: 'white',
                  borderRadius: '10px',
                }}
              >
                Get Appointment
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
