import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import about from '../Components/Images/About.jpg'
import '../Css/About.css'

const About = () => {
  return (
    <div>
    <Navbar/>
    <div className='aboutimgdiv'>
      <img className='aboutimg' src={about} alt="about" />
    </div>

    <h1 style={{textAlign: 'center', fontFamily: 'sans-serif', marginTop: '5%'}}><b>About Us</b></h1>
    <hr style={{borderColor: 'lightcoral'}}/>
      <div className='introductiontext'>
      <h3 style={{color: '#900C3F', fontFamily: 'sans-serif', marginLeft: '2%', fontSize: '150%'}}><b>Presenting AMDC: Enhancing Your Dental and Aesthetic Experience</b></h3>
      <p style={{fontFamily: 'sans-serif', color: '#C70039', marginLeft: '2%', fontSize: '140%', lineHeight: '180%'}}>Experience the epitome of excellence at AMDC, the leading clnic for dental and cosmetic procedures, where cutting-edge techniques merge with a commitment to aesthetics, medical proficiency, and beauty. We aim to redefine your understanding of restorative and cosmetic treatments, delivering unmatched care and outcomes for you and your family.</p>
      <h3 style={{color: '#900C3F', fontFamily: 'sans-serif', marginLeft: '2%', fontSize: '150%'}}><b>Unveiling a State-of-the-Art Facility:</b></h3>
      <p style={{fontFamily: 'sans-serif', color: '#C70039', marginLeft: '2%', fontSize: '140%', lineHeight: '180%'}}>Embark on a journey of luxury as you enter our meticulously curated clinic, where every aspect has been thoughtfully designed to enhance your comfort and convenience. Our lavish ambiance radiates sophistication, while our fully equipped dental and aesthetic rooms showcase our dedication to providing outstanding services. Conviniently located near the Sheeba Park, accessing AMDC is effortless.</p>
      <h3 style={{color: '#900C3F', fontFamily: 'sans-serif', marginLeft: '2%', fontSize: '150%'}}><b>Unmatched Unparalleled Proficiency, Holistic Solutions:</b></h3>
      <p style={{fontFamily: 'sans-serif', color: '#C70039', marginLeft: '2%', fontSize: '140%', lineHeight: '180%'}}>At AMDC, we boast an ensemble of specialists from varied dental disciplines, allowing us to provide a wide array of services all in one place. From conventional and prosthetic dentistry to state-of-the-art aesthetic treatments, our esteemed dentists leverage their specialized expertise to cater to your individual needs. Say goodbye to the inconvenience of visiting multiple locations for your dental and cosmetic needs—AMDC is your comprehensive destination.</p>
      <h3 style={{color: '#900C3F', fontFamily: 'sans-serif', marginLeft: '2%', fontSize: '150%'}}><b>A Customized Experience, Exclusively for You:</b></h3>
      <p style={{fontFamily: 'sans-serif', color: '#C70039', marginLeft: '2%', fontSize: '140%', lineHeight: '180%'}}>We recognize the value of your time. Hence, our appointment system is meticulously crafted to be adaptable, seamlessly fitting into your hectic agenda. Our attentive team is committed to delivering tailored care, attentively addressing your specific needs, guranteeing a smooth and graftifying experience throughout your association with us. Rely on AMDC to surpass your anticipations.</p>
      <h3 style={{color: '#900C3F', fontFamily: 'sans-serif', marginLeft: '2%', fontSize: '150%'}}>Enhance Your Dental and Aesthetic Experience with AMDC:</h3>
      <p style={{fontFamily: 'sans-serif', color: '#C70039', marginLeft: '2%', fontSize: '140%', lineHeight: '180%'}}>Get in touch with us today to begin a transformative journey toward enhanced beauty, wellness, and confidence. Let's work together to create a radiant smile and a revitalized you.</p>
      </div>
      <div className='meetthestaffdiv'>
        <h1 style={{ fontSize: '320%', textAlign: 'center'}}>Our Staff</h1>
        <hr style={{width: '120px', marginLeft: '5px', borderColor: 'lightcoral', margin: '0px auto'}} />
        <p style={{fontFamily: 'sans-serif', color: '#C70039', marginLeft: '2%', fontSize: '140%', lineHeight: '180%'}}>At AMDC, our team comprises exceptionally skilled professionals who possess extensive expertise and years of valuable experience in the fields of dentistry and aesthetics. Their profound knowledge and background enable them to consistently deliver exceptional value to our patients.</p>
        </div>
    <Footer/>
    </div>
  )
}

export default About