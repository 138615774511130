import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from 'react-router-dom';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: 'lightcoral' }} className="py-5 text-white">
      <div className="container">
        <div className="row text-center text-md-start">
          {/* Call Us */}
          <div className="col-md-3 mb-4">
            <h4 className="mb-3">Call Us</h4>
            <p>+92 308 4700906</p>
            <p>+92 335 3331138</p>
            <p>+92 321 8403832</p>
          </div>

          {/* Reach Us */}
          <div className="col-md-3 mb-4">
            <h4 className="mb-3">Reach Us</h4>
            <p>331-Z, 1st Floor, Phase-3 DHA, Lahore</p>
          </div>

          {/* Open Hours */}
          <div className="col-md-3 mb-4">
            <h4 className="mb-3">Open Hours</h4>
            <p>Mon-Sat 04:00 - 09:00</p>
          </div>

          {/* Follow Us */}
          <div className="col-md-3 mb-4">
            <h4 className="mb-3">Follow Us</h4>
            <div className="d-flex justify-content-center justify-content-md-start">
              <NavLink to="https://www.facebook.com/amdcclinics" className="me-3 text-white">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </NavLink>
              <NavLink to="https://www.instagram.com/amdcclinics/?hl=en" className="me-3 text-white">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </NavLink>
              <NavLink to="https://www.google.com/maps/place//data=!4m3!3m2!1s0x3919059f1ab46d0f:0x13aae198cdde2f2f!12e1?source=g.page.m.nr._&laa=nmx-review-solicitation-recommendation-card" className="me-3 text-white">
                <FontAwesomeIcon icon={faGoogle} size="2x" />
              </NavLink>
              <NavLink to="https://www.google.com/maps/place/Al+Mairaj+Dental+%26+Cosmetics+(AMDC)/@31.4992396,74.3644296,17z/data=!3m1!4b1!4m5!3m4!1s0x3919059f1ab46d0f:0x13aae198cdde2f2f!8m2!3d31.499235!4d74.3666183?coh=164777&entry=tt&shorturl=1" className="text-white">
                <FontAwesomeIcon icon={faLocationArrow} size="2x" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
